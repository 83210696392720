import React from 'react'

import { HelmetSEO } from '../../components/Helmet/HelmetSEO'

import './historia.scss'

import WarningBadge from '../../components/WarningBadge/WarningBadge'

import TimelineBannerHistory from '../../statics/images/Historia/Fabrica.jpeg'
import TimelineBannerHistory2 from '../../statics/images/Historia/LOGOpilsener.jpeg'
import TimelineBannerHistory3 from '../../statics/images/Historia/Comercial1993.jpeg'
import TimelineBannerHistory4 from '../../statics/images/Historia/seleccionhimno.jpeg'
import TimelineBannerHistory5 from '../../statics/images/Historia/imghistoria5.png'
import TimelineBannerHistory6 from '../../statics/images/Historia/Cristalcero-radler.jpeg'
import TimelineBannerHistory7 from '../../statics/images/Historia/imghistoria7.png'
import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'

const ArrayOfTimeline = [
  {
    year: '1850',
    text: 'Se crea la primera fábrica de cerveza en Chile Joaquín Plagemann instala en Valparaíso la primera fábrica de cerveza del país.',
    img: false,
    positionImage: '',
    colorBackground: 'green',
  },
  {
    year: '1889',
    text: 'La cervecería de Joaquín Plagemann se fusiona con la Fábrica de Cerveza de Limache Hoffmann y Ribbeck, dando origen a la Fábrica Nacional de Cerveza.',
    img: TimelineBannerHistory,
    positionImage: 'bottom',
    colorBackground: 'gray',
  },
  {
    year: '1902',
    text: 'Se forma la Compañía de Cervecerías Unidas, CCU, con la fusión de las cerveceras de Joaquín Plagemann, la Fábrica de cerveza de Limache y la Fábrica de Cerveza y Hielo Gubler y Cousiño.',
    img: false,
    positionImage: '',
    colorBackground: 'gray',
  },
  {
    year: '1916',
    text: 'CCU se transforma en el principal productor de cerveza del país y en líder nacional al adquirir la Fábrica Ebner de Santiago, Anwandter de Valdivia y la Compañía Cervecera La Calera.',
    img: false,
    positionImage: '',
    colorBackground: 'gray',
  },
  {
    year: '1978',
    text: 'La clásica y tradicional cerveza lager de cuerpo balanceado y refrescante, se lanza al mercado bajo el nombre de Pilsener Cristal.',
    img: TimelineBannerHistory2,
    positionImage: 'bottom',
    colorBackground: 'green',
  },
  {
    year: '1981',
    text: 'Se inaugura Cervecera Santiago, la planta de cerveza más moderna del país hasta esa fecha.',
    img: false,
    positionImage: '',
    colorBackground: 'green',
  },
  {
    year: '1993',
    text: '“Única, Grande y Nuestra” A partir de este slogan, Cristal se convierte en un ícono publicitario, destacándose por sus grandes campañas.',
    img: false,
    positionImage: '',
    colorBackground: 'green',
  },
  {
    year: '2000',
    text: 'Primera marca en ingresar al Marketing Hall of Fame de Chile por su innovadora publicidad.',
    img: TimelineBannerHistory3,
    positionImage: 'top',
    colorBackground: 'gray',
  },
  {
    year: '2003',
    text: 'Cerveza Cristal obtiene el Grand Prix de Medios en Cannes con su campaña “La fuerza está con Cerveza Cristal”, emitido en televisión abierta. Este mismo año Cristal comienza con su auspicio a nuestra Selección como cúspide del apoyo al fútbol y campeonato nacional.',
    img: TimelineBannerHistory4,
    positionImage: 'bottom',
    colorBackground: 'gray',
  },
  {
    year: '2008',
    text: 'Se lanza Cristal 0. La primera cerveza lager 0,0 grados de alcohol elaborada en Chile, bajo un proceso especial de extracción del alcohol con tecnología de vanguardia.',
    img: false,
    positionImage: '',
    colorBackground: 'green',
  },
  {
    year: '2009',
    text: 'Cristal actualiza su logotipo e imágen.',
    img: TimelineBannerHistory5,
    positionImage: 'bottom',
    colorBackground: 'green',
  },
  {
    year: '2012',
    text: 'Se lanza Cristal Light al mercado, una nueva categoría de cerveza muy exitosa en mercados internacionales, que viene a satisfacer nuevas necesidades de los consumidores Chilenos.',
    img: false,
    positionImage: '',
    colorBackground: 'gray',
  },
  {
    year: '2015',
    text: 'Se lanza la colección 165 años de tradición cervecera, conmemorando la marca y su legado.',
    img: false,
    positionImage: '',
    colorBackground: 'gray',
  },
  {
    year: '2016',
    text: 'Se lanza Cristal 0 Radler. Una cerveza lager 0,0 grados de alcohol que tiene jugo de limon 100% natural, lo que la hace muy refrescante.',
    img: TimelineBannerHistory6,
    positionImage: 'bottom',
    colorBackground: 'green',
  },
  {
    year: '2019',
    text: 'Se lanza Cristal Summer Lager al mercado, una nueva cerveza que vino a refrescar el verano, con ingredientes 100% naturales y lúpulos que le dan suaves notas cítricas.',
    img: false,
    positionImage: '',
    colorBackground: 'green',
  },
  {
    year: '2021',
    text: 'Se lanza Cristal La Roja, una cerveza Red Lager inspirada en la pasión de los hinchas chilenos.',
    img: TimelineBannerHistory7,
    positionImage: 'bottom',
    colorBackground: 'gray',
  },
]

export const Historia = () => {
  return (
    <>
      <HelmetSEO title={'Historia'} />

      <ValidarEdad />

      <div className="History__hero">
        <WarningBadge />
        <div className="History__hero__content">
          <div className="History__content__top"></div>

          <div className="History__content__bottom">
            <div className="Content__bottom__text">
              <div className="Bottom__text__center">
                <span>Nuestra Historia</span>
                <h1>Tradición cervecera</h1>
                <p>
                  Una tradición cervecera de más de 170 años, que ha acompañado
                  a Chile a lo largo del tiempo y lo seguirá haciendo. Con
                  historia, innovaciones y lo más importante, juntarnos con
                  quienes más queremos al rededor de unas Cristal, para seguir
                  RErefrescándonos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="History__timeline">
        <div className="History__timeline__content">
          <ul className="History__timeline__list">
            {ArrayOfTimeline.map((item, i) => {
              return (
                <li
                  key={i}
                  className={`${item.colorBackground} ${item.positionImage}`}
                >
                  <div className="Timeline__content__rigth">
                    <div className="Card__timeline">
                      <div className="Card__content">
                        <div className="Card__content__text">
                          <span>{item.year}</span>
                          <p>{item.text}</p>
                        </div>
                        {item.img ? (
                          <div className="Card__content__image">
                            <img src={item.img} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}
