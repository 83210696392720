import React from 'react'
import Base1 from '../../../statics/Bases-Concurso Cristal-Entradas-a-fonda-Parque-Ohiggins.pdf'
import Base2 from '../../../statics/Bases-Oferta-Cristal-12pk-470-7500-nacional[5640897].pdf'
import Base3 from '../../../statics/Bases-Oferta-12pk-350-Cristal-Arica-Iquique-6000[5640325].pdf'
import Base4 from '../../../statics/Bases-Raspe-Cristal-18-6pk-470-nacional[5673046].pdf'
import Base5 from '../../../statics/Bases-Instore-Cristal-6-PK-470-Resto-de-Chile-Cervecera-2022.pdf'
import Base6 from '../../../statics/Bases-Oferta-12pk-Cristal-lata-350-5900 Antofagasta-Copiapo[5120158].pdf'
import Base7 from '../../../statics/Bases-Intore-Cristal-Centro-Sur-12-pk-470-Cervecera.pdf'
import Base8 from '../../../statics/Bases-Extension-oferta-GRB-12-Cristal-y-Escudo-a-1000[5120215].pdf'
import Base9 from '../../../statics/Bases-RETORNABLE 500 CC A 500 LA FLORIDA CRISTAL Y ESCUDO_compressed.pdf'
import Base10 from '../../../statics/Ganadores-Cristal-(Petroleras-SISA).pdf'
import Base11 from '../../../statics/Bases-Cristal-entradas-Bierfest-Oktoberfest.pdf'
import Base12 from '../../../statics/Bases-Concurso-futbolero-Cristal.pdf'
import Base13 from '../../../statics/CCU-GANADORES-RETORNABLES.pdf'
import Base14 from '../../../statics/Bases-Sorteos-Futboleros-Cristal-Diciembre-Cervecera-2022.pdf'
import Base15 from '../../../statics/GANADORES-RETORNABLES-CCU.pdf'
import Base16 from '../../../statics/Bases-Cómo-termina-la-jugada-Cristal.pdf'
import Base17 from '../../../statics/Bases - Concurso Cristal Origenes La  Barra - Cervecera - 2023.pdf'
import Base18 from '../../../statics/BASES CRISTAL ORIGENES - junio 2023.pdf'
import Base19 from '../../../statics/BASES  CONCURSO - Concurso día de la amistad - Cristal julio 2023.pdf'
import Base20 from '../../../statics/Bases-Concurso-Sillon-Cristal-Sept 2024-septiembre-2023pdf-firmado[8894907].pdf'
import Base21 from '../../../statics/Bases Concurso Instagram Cristal 18 septiembre 2023 - septiembre 2023 para firmapdf-firmado[8894953].pdf'
import Base22 from '../../../statics/Bases Concurso - Instagram Cristal (Paraguay - Nov 2023).pdf'
import Base23 from '../../../statics/Concurso Cristal X Star Wars - Refresca la Galaxia - mar 2024[11012710].pdf'
import Base24 from '../../../statics/Bases Challenge Cristal IG - abril 2024 CCU CHILEpdf-firmado[12410748].pdf'
import Base25 from '../../../statics/Bases Concurso - Sillón Cristal (Paraguay - Jun 2024).pdf'
import Base26 from '../../../statics/Bases Concurso Chile VS Argentina en USA.pdf-firmado.pdf'
import Base27 from '../../../statics/Bases Sillon Cristal - Octubre 2024-firmado.pdf'

import './basemodal.scss'

export const BaseModal = () => {
  const handleModaDialogClick = (e) => {
    e.stopPropagation(true)
  }

  const modalClose = () => {
    const floatCard = document.querySelector('.Float__card')

    setTimeout(() => {
      if (floatCard.classList.contains('out')) {
        floatCard.classList.remove('out')
      }
    }, 50)
  }

  return (
    <div className="ModalBases">
      <div className="bases__modal">
        <div className="bases__modal__wrap">
          <p onClick={modalClose}>X</p>
          <div
            className="bases__modal__sections"
            onClick={handleModaDialogClick}
          >
            <h3>Bases y Condiciones</h3>
          </div>
          <div
            className="bases__modal__sections"
            onClick={handleModaDialogClick}
          >
            <a href={Base27} target="_blank" rel="noopener noreferrer">
              Bases Sillon Cristal - Octubre 2024
            </a>
            <br />
            <a href={Base26} target="_blank" rel="noopener noreferrer">
              Bases Concurso Chile VS Argentina en USA - Junio 2024
            </a>
            <br />
            <a href={Base25} target="_blank" rel="noopener noreferrer">
              Bases Sillón Cristal - Junio 2024
            </a>
            <br />
            <a href={Base24} target="_blank" rel="noopener noreferrer">
              Bases Challenge Cristal IG - Abril 2024 CCU CHILE
            </a>
            <br />
            <a href={Base23} target="_blank" rel="noopener noreferrer">
              BASES DE CONCURSO - CRISTAL X STAR WARS 2024
            </a>
            <br />
            <a href={Base22} target="_blank" rel="noopener noreferrer">
              BASES DE CONCURSO - INSTAGRAM CRISTAL NOV 2023
            </a>
            <br />
            <a href={Base21} target="_blank" rel="noopener noreferrer">
              Bases Concurso Instagram SEPT 2024
            </a>
            <br />
            <a href={Base20} target="_blank" rel="noopener noreferrer">
              SILLÓN CRISTAL SEPT 2024
            </a>
            <br />
            <a href={Base19} target="_blank" rel="noopener noreferrer">
              Concurso día de la amistad
            </a>
            <br />
            <a href={Base18} target="_blank" rel="noopener noreferrer">
              Concurso Cristal Origenes
            </a>
            <br />
            <a href={Base17} target="_blank" rel="noopener noreferrer">
              Concurso Cristal Origenes La Barra
            </a>
            <br />
            <a href={Base16} target="_blank" rel="noopener noreferrer">
              CÓMO TERMINA LA JUGADA CRISTAL - CCU
            </a>
            <br />
            <a href={Base15} target="_blank" rel="noopener noreferrer">
              GANADORES RETORNABLES - CCU
            </a>
            <br />
            <a href={Base14} target="_blank" rel="noopener noreferrer">
              BASES DE CONCURSO “FUTBOLERO CRISTAL DICIEMBRE“
            </a>
            <br />
            <a href={Base13} target="_blank" rel="noopener noreferrer">
              GANADORES RETORNABLES - CCU
            </a>
            <br />
            <a href={Base12} target="_blank" rel="noopener noreferrer">
              BASES DE CONCURSO “FUTBOLERO CRISTAL“ - CERVECERA CCU CHILE
              LIMITADA
            </a>
            <br />
            <a href={Base11} target="_blank" rel="noopener noreferrer">
              BASES DE CONCURSO “ENTRADAS BIERFEST OKTOBER PARQUE PADRE HURTADO”
            </a>
            <br />
            <a href={Base10} target="_blank" rel="noopener noreferrer">
              Ganadores Cristal
            </a>
            <br />
            <a href={Base1} target="_blank" rel="noopener noreferrer">
              BASES DE PROMOCIÓN “CRISTAL ENTRADAS FONDA PARQUE O`HIGGINS“
              CERVECERA CCU CHILE LIMITADA
            </a>
            <br />
            <a href={Base2} target="_blank" rel="noopener noreferrer">
              BASES LEGALES DE OFERTA “CRISTAL 12 PK 470 CRISTAL $7.500
              NACIONAL”
            </a>
            <br />
            <a href={Base3} target="_blank" rel="noopener noreferrer">
              BASES LEGALES DE OFERTA “12 PACK LT 350 CRISTAL A 6000 ARICA
              IQUIQUE” CERVECERA CCU CHILE LIMITADA
            </a>
            <br />
            <a href={Base4} target="_blank" rel="noopener noreferrer">
              BASES PROMOCION “RASPE CRISTAL 18 SEPTIEMBRE TOTAL CHILE 6PK 470”
            </a>
            <br />
            <a href={Base5} target="_blank" rel="noopener noreferrer">
              BASES PROMOCION “BANDEO CRISTAL MAYO A JULIO RESTO DE CHILE”
            </a>
            <br />
            <a href={Base6} target="_blank" rel="noopener noreferrer">
              BASES LEGALES DE OFERTA “12 PACK LT 350 ESCUDO A 5900 ANTOFAGASTA
              ATACAMA”
            </a>
            <br />
            <a href={Base7} target="_blank" rel="noopener noreferrer">
              BASES PROMOCION “BANDEO CRISTAL MAYO A JULIO CENTRO SUR”
            </a>
            <br />
            <a href={Base8} target="_blank" rel="noopener noreferrer">
              MODIFICACIÓN DE BASES LEGALES DE OFERTA “CRISTAL Y ESCUDO 1.2 GRB
              A 1000”
            </a>
            <br />
            <a href={Base9} target="_blank" rel="noopener noreferrer">
              BASES LEGALES DE OFERTA CERVECERA CCU CHILE LIMITADA
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
