import React from 'react'
import selloAdvertencia from '../../statics/sello-advertencia.png'

import './warning-badge.scss'

const WarningBadge = () => {
  return (
    <div className="Badge__warning">
      <div className="warning__container">
        <img src={selloAdvertencia} alt="Sello de advertencia" />
      </div>
    </div>
  )
}

export default WarningBadge
