import React from 'react'

import { HelmetSEO } from '../../components/Helmet/HelmetSEO'

import './campanias.scss'

import WarningBadge from '../../components/WarningBadge/WarningBadge'

import ImageCardTimeline1 from '../../statics/images/Campanias/analogia.jpg'
import ImageCardTimeline2 from '../../statics/images/Campanias/Retornabilidad.jpg'
import ImageCardTimeline3 from '../../statics/images/Campanias/CHI.jpg'
import ImageCardTimeline4 from '../../statics/images/Campanias/YSVPUC.jpg'
import ImageCardTimeline5 from '../../statics/images/Campanias/CRISTAL_LA_ROJA.jpg'
import ImageCardTimeline6 from '../../statics/images/Campanias/PONGAMOS_EL_HOMBRO.jpg'
import ImageCardTimeline7 from '../../statics/images/Campanias/18_RAZONES.jpg'
import ImageCardTimeline8 from '../../statics/images/Campanias/ES_LA_MAS_REREFRESCANTE.jpg'
import ImageCardTimeline9 from '../../statics/images/Campanias/campañaverano.jpg'
import ImageCardTimeline10 from '../../statics/images/Campanias/img2022.jpg'
import ImageCardTimeline11 from '../../statics/images/Campanias/18desep2022.jpg'
import ImageCardTimeline12 from '../../statics/images/Campanias/teleton2022.jpg'
import ImageCardTimeline13 from '../../statics/images/Campanias/img-retornable2022.jpg'
import video from '../../statics/images/Campanias/SpotCredenciales.mp4'
import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'

const ArrayOfTimeline = [
  {
    year: '2022',
    title: 'Cristal tiene botellas REtornables',
    image: ImageCardTimeline13,
    positionTimeline: 'left',
    link: 'https://www.youtube.com/embed/R1OWS1TAVYw',
  },
  {
    year: '2022',
    title: 'Teletón 2022',
    image: ImageCardTimeline12,
    positionTimeline: 'rigth',
    link: 'https://www.youtube.com/embed/d1o-mjSbqLY',
  },
  {
    year: '2022',
    title: '¡RErefresquemos el 18!',
    image: ImageCardTimeline11,
    positionTimeline: 'left',
    link: 'https://www.youtube.com/embed/LKZw-LkaAVU',
  },
  {
    year: '2022',
    title: '',
    image: ImageCardTimeline10,
    positionTimeline: 'rigth',
    link: video,
  },
  {
    year: '2020',
    title: 'Y si vamos por una Cristal?',
    image: ImageCardTimeline4,
    positionTimeline: 'left',
    link: 'https://www.youtube.com/embed/edt73kHgDjg',
  },
  {
    year: '2020',
    title: 'Un C-H-I único, grande y nuestro',
    image: ImageCardTimeline3,
    positionTimeline: 'rigth',
    link: 'https://www.youtube.com/embed/F51lowEz9Xs',
  },
  {
    year: '2020',
    title: 'Retornable',
    image: ImageCardTimeline2,
    positionTimeline: 'left',
    link: 'https://www.youtube.com/embed/2x0Lzv0sTxA',
  },
  {
    year: '2021',
    title: 'Envasado en frio / Analogía',
    image: ImageCardTimeline1,
    positionTimeline: 'rigth',
    link: 'https://www.youtube.com/embed/oA3epjhpBHM',
  },
  {
    year: '2021',
    title: 'Cristal La Roja',
    image: ImageCardTimeline5,
    positionTimeline: 'left',
    link: 'https://www.youtube.com/embed/M50c6offzas',
  },
  {
    year: '2021',
    title: 'Pongamos el hombro',
    image: ImageCardTimeline6,
    positionTimeline: 'rigth',
    link: 'https://www.youtube.com/embed/GIYZvczFTUc',
  },
  {
    year: '2021',
    title: '18 razones',
    image: ImageCardTimeline7,
    positionTimeline: 'left',
    link: 'https://www.youtube.com/embed/4fwL5r9Le98',
  },
  {
    year: '2021',
    title: 'Cristal es más que Re refrescante:',
    image: ImageCardTimeline8,
    positionTimeline: 'rigth',
    link: 'https://www.youtube.com/embed/cMBPIvSsi_o',
  },
  {
    year: '2021',
    title: 'Rerefrescante para aliviar la sed del verano',
    image: ImageCardTimeline9,
    positionTimeline: 'left',
    link: 'https://www.youtube.com/embed/lamqi5646HU',
  },
]

export const Campanias = () => {
  return (
    <>
      <HelmetSEO title={'Campañas'} />

      <ValidarEdad />

      <div className="Campaings__hero">
        <WarningBadge />
        <div className="Campaings__hero__content">
          <div className="Campaings__content__top"></div>

          <div className="Campaings__content__bottom">
            <div className="Content__bottom__text">
              <div className="Bottom__text__center">
                <span>¿Cuál es la que más te gusta?</span>
                <h1>Campañas</h1>
                <p>
                  Conoce las campañas que han refrescado a todos los chilenos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Campaings__timeline">
        <div className="Campaings__timeline__content">
          <ul className="Campaings__timeline__list">
            {ArrayOfTimeline.map((item, index) => {
              return (
                <li key={index} className={item.positionTimeline}>
                  <div className="Timeline__content__rigth">
                    <div className="Card__timeline">
                      <div className="Card__content">
                        <div className="Card__content__text">
                          <span>{item.year}</span>
                          <h2>{item.title}</h2>
                        </div>
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="Card__content__img">
                            <img src={item.image} alt="" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="timeline__content__left"></div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}
